import {
  signInWithGoogle,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword
} from "../services/firebase";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import logo from "./logo_tilla_white.svg"

import React, { useState } from "react";

import "../App.css";

const Login = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  return (
    <div>
    <div className="container-fluid" style={{ marginTop: "2.5%" }}>
      <div className="row">
        <div className="col" style={{margin: "5%", marginBottom: "6%", textAlign: "center"}}>
          {/* <img src="https://b386363e680359b5cc19-97ec1140354919029c7985d2568f0e82.ssl.cf1.rackcdn.com/assets/uploads/setting/company_header_logo/19393/optimized_76065301d5d60ff7b15bce1f3e5f3f86.png" width={"120px"} style={{float: "inherit"}}></img> */}
          {/* <img src="https://uploads-ssl.webflow.com/632ae3c1946ddb2ff81123b0/636cd326b0c59e756deef33a_Sitruna%20Logo-p-500.png" width={"240px"} style={{float: "inherit"}}></img> */}
          <img src={logo} width={"240px"} style={{float: "inherit"}}></img>
        </div>
      </div>
      <div className="row">
        <div className="col col-4"></div>
        <div className="col col-8">
          <div className="row">
            <div className="col col-6">
              <Card style={{backgroundColor: '#ffffff', borderColor: '#ffffff'}}>
                <Card.Body>
                  <Card.Title style={{color: '#000000'}} >Login</Card.Title>
                  <div>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{color: '#000000'}} >Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                      />
                      {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label style={{color: '#000000'}} >Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                      />
                    </Form.Group>
                    <hr style={{ margin: "5%" }} />

                    <div className="d-grid gap-2">
                      <Button
                        // variant="#f4b223"
                        style={{backgroundColor: '#2ed9c3', borderColor: '#2ed9c3'}}
                        type="submit"
                        onClick={() => {
                          signInWithEmailAndPassword(loginEmail, loginPassword);
                        }}
                        onMouseOver={(e) => {
                          e.target.style.backgroundColor = '#26c4b7';
                          e.target.style.borderColor = '#26c4b7';
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundColor = '#2ed9c3';
                          e.target.style.borderColor = '#2ed9c3';
                        }}
                      >
                        Login
                      </Button>
                      {/* <Button
                        variant="primary"
                        onClick={signInWithGoogle}
                      >
                        <i className="fab fa-google"></i>Sign-in with Google
                      </Button> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            {/* <div className="col col-6">
              <Card>
                <Card.Body>
                  <Card.Title>User Registration</Card.Title>
                  <Card.Text>First time visiting? Register here</Card.Text>
                  <div>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <hr style={{ margin: "5%" }} />

                    <div className="d-grid gap-2">
                      <Button
                        variant="outline-danger"
                        type="submit"
                        onClick={() => {
                          registerWithEmailAndPassword(name, email, password);
                        }}
                      >
                        Register
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div> */}
          </div>
        </div>
        <div className="col-col-4"></div>
      </div>
    </div>
    <div class="container"  style={{marginTop: "11.5%"}}>
      <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div class="col-md-4 d-flex align-items-center">
          <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          </a>
          <span class="mb-3 mb-md-0 text-muted">&copy; Copyright 2024 Tilladelse</span>
        </div>
    
        <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li class="ms-3"><a class="text-muted" href="https://tilladelse.com"><i class="bi bi-link-45deg"></i></a></li>
          {/* <li class="ms-3"><a class="text-muted" href="https://twitter.com/DataStudioLabs"><i class="bi bi-twitter"></i></a></li>
          <li class="ms-3"><a class="text-muted" href="https://www.facebook.com/DataStudioLabs"><i class="bi bi-facebook"></i></a></li>
          <li class="ms-3"><a class="text-muted" href="https://www.instagram.com/datastudiolabs"><i class="bi bi-instagram"></i></a></li> */}
        </ul>
      </footer>
    </div>
  </div>
  );
};

export default Login;
